import { z } from "zod";

export namespace ThemingApi {
  export namespace ProductScrollType {
    export const schema = z.enum(["horizontal", "vertical"]);
    export type Type = z.infer<typeof schema>;
  }

  export namespace ResponsiveFontStyling {
    export const schema = z.enum(["static", "responsive"]);
    export type Type = z.infer<typeof schema>;
  }

  export namespace ModernThemeSettings {
    export const schema = z.object({
      sections: z.object({
        banner: z.object({
          display: z.boolean(),
          text: z.string(),
          deleted: z.boolean(),
        }),
        subscriptions: z.object({
          display: z.boolean(),
          addOns: z.boolean(),
          deleted: z.boolean(),
          altView1: z.boolean(),
        }),
        settings: z.object({
          display: z.boolean(),
          deleted: z.boolean(),
        }),
        quickBuy: z.object({
          display: z.boolean(),
          deleted: z.boolean(),
        }),
        orderHistory: z.object({
          display: z.boolean(),
          deleted: z.boolean(),
        }),
        rewards: z.object({
          display: z.boolean(),
          deleted: z.boolean(),
        }),
        reviews: z.object({
          display: z.boolean(),
          deleted: z.boolean(),
        }),
        wishlist: z.object({
          display: z.boolean(),
          deleted: z.boolean(),
        }),
        instagram: z.object({
          display: z.boolean(),
        }),
        trending: z.object({
          display: z.boolean(),
          heading: z.string(),
          caption: z.string(),
          showHeart: z.boolean(),
          imgUrl: z.string(),
          sortMethod: z.enum([
            "manual",
            "productTitleAZ",
            "newest",
            "oldest",
            "productPriceHighest",
            "productPriceLowest",
            "bestSelling",
            "productInventoryHighest",
            "productInventoryLowest",
          ]),
        }),
      }),

      sectionsOrder: z.array(
        z.enum(["SUBSCRIPTION", "LOYALTY", "ORDERHISTORY", "SETTINGS", "INSTAGRAM", "TRENDING"])
      ),

      welcomeInfo: z.object({
        welcomeMessage: z.string(),
        customerFirstName: z.boolean(),
        customerEmail: z.boolean(),
        customerSinceDate: z.boolean(),
      }),

      nextOrderActions: z.object({
        sendNow: z.boolean(),
        skip: z.boolean(),
        gift: z.boolean(),
        swap: z.boolean(),
        delay: z.boolean(),
        editAddress: z.boolean(),
      }),

      subscriptionActions: z.object({
        editFrequency: z.boolean(),
        setNextOrderDate: z.boolean(),
        editSubscriptionAddress: z.boolean(),
        editPayment: z.boolean(),
        pause: z.boolean(),
        showVariantName: z.boolean(),
        subscriptionSwap: z.boolean(),
      }),

      showHide: z.object({
        showVariantName: z.boolean(),
        showPortalMenu: z.boolean(),
        showUpcomingOrders: z.boolean(),
        showDiscountCodeInput: z.boolean(),
        showSectionTitles: z.boolean(),
        showAddItemAsSub: z.boolean(),
      }),

      productScrollType: ThemingApi.ProductScrollType.schema,
      fontSizingType: ThemingApi.ResponsiveFontStyling.schema,
    });

    export type Type = z.infer<typeof schema>;
  }

  export namespace CustomerPortalTheme {
    export const schema = z.object({
      overrideCSS: z.string().optional(),
      themeName: z.string(),

      // Modern Theme Values
      // Main Colors
      modernPageBackground: z.string(),
      modernCardBackground: z.string(),
      modernMenuBackground: z.string(),
      modernBadgeBackground: z.string(),
      modernIconsColor: z.string(),
      modernLineDividers: z.string(),

      // Borders
      modernCardRadius: z.number(),
      modernCardBorder: z.number(),
      modernCardBorderColor: z.string(),
      modernSmallCardBorderRadius: z.number(),
      modernSmallCardBorderWidth: z.number(),
      modernSmallCardBorderColor: z.string(),

      // Buttons
      modernPrimaryButtonColor: z.string(),
      modernPrimaryButtonBorderColor: z.string(),
      modernSecondaryButtonColor: z.string(),
      modernSecondaryButtonBorderColor: z.string(),
      modernButtonBorderWidth: z.number(),
      modernButtonBorderRadius: z.number(),
      modernButtonBorderHeight: z.number(),

      isPasswordless: z.boolean(),
      passwordlessStrategy: z.enum(["otp", "magic-link"]),

      lastSavedThemeId: z.string(),

      modernThemeSettings: ThemingApi.ModernThemeSettings.schema,
      translationOverrides: z.any(),

      // Modern badges
      modernActiveBadgeColor: z.string(),
      modernPausedBadgeColor: z.string(),
      modernCancelledBadgeColor: z.string(),

      // Banner
      modernBannerColor: z.string(),

      modernImageBorderRadius: z.number(),
      modernSmallImageBorderRadius: z.number(),

      modernOverrideCSS: z.string().optional(),

      // Modern Fonts
      // Extra large
      modernExtraLargeFontFamily: z.string(),
      modernExtraLargeFontSize: z.number(),
      modernExtraLargeFontWeight: z.string(),
      modernExtraLargeFontLineHeight: z.number(),
      modernExtraLargeFontLetterSpacing: z.number(),
      modernExtraLargeFontColor: z.string(),

      // Large
      modernLargeFontFamily: z.string(),
      modernLargeFontSize: z.number(),
      modernLargeFontWeight: z.string(),
      modernLargeFontLineHeight: z.number(),
      modernLargeFontLetterSpacing: z.number(),
      modernLargeFontColor: z.string(),

      // Medium
      modernMediumFontFamily: z.string(),
      modernMediumFontSize: z.number(),
      modernMediumFontWeight: z.string(),
      modernMediumFontLineHeight: z.number(),
      modernMediumFontLetterSpacing: z.number(),
      modernMediumFontColor: z.string(),

      // Small
      modernSmallFontFamily: z.string(),
      modernSmallFontSize: z.number(),
      modernSmallFontWeight: z.string(),
      modernSmallFontLineHeight: z.number(),
      modernSmallFontLetterSpacing: z.number(),
      modernSmallFontColor: z.string(),

      // Subheading
      modernSubHeadingFontFamily: z.string(),
      modernSubHeadingFontSize: z.number(),
      modernSubHeadingFontWeight: z.string(),
      modernSubHeadingFontLineHeight: z.number(),
      modernSubHeadingFontLetterSpacing: z.number(),
      modernSubHeadingFontColor: z.string(),
      isSubHeadingUppercase: z.boolean(),

      // Button CTA
      modernButtonCTAFontFamily: z.string(),
      modernButtonCTAFontSize: z.number(),
      modernButtonCTAFontWeight: z.string(),
      modernButtonCTAFontLineHeight: z.number(),
      modernButtonCTAFontLetterSpacing: z.number(),
      modernPrimaryButtonFontColor: z.string(),
      modernSecondaryButtonFontColor: z.string(),

      // Body
      modernBodyFontFamily: z.string(),
      modernBodyFontSize: z.number(),
      modernBodyFontWeight: z.string(),
      modernBodyFontLineHeight: z.number(),
      modernBodyFontLetterSpacing: z.number(),
      modernBodyFontColor: z.string(),

      // Caption
      modernCaptionFontFamily: z.string(),
      modernCaptionFontSize: z.number(),
      modernCaptionFontWeight: z.string(),
      modernCaptionFontLineHeight: z.number(),
      modernCaptionFontLetterSpacing: z.number(),
      modernCaptionFontColor: z.string(),
    });

    export type Type = z.infer<typeof schema>;
  }
}
