import { ICustomerPortalTheme } from "@smartrr/shared/entities/CustomerPortalTheme";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";
import { setNestedProperty } from "@smartrr/shared/utils/setNestedProperty";
import { isEqual, isUndefined } from "lodash";
import { IInstaAllPostData } from "@smartrr/shared/entities/Instagram";
import { ThemeSettingsTabs } from "../sections/Main/libs/constants";
import { defaultCustomerPortalThemeConfig } from "@smartrr/shared/themes/defaultThemeConfig";
import { ThemingApi } from "@smartrr/shared/interfaces/theming/api";

interface ThemingStore {
  initialThemeValues: ICustomerPortalTheme | null;
  currentThemeValues: ICustomerPortalTheme | null;
  overrideCSSInput: string;
  modernOverrideCSSInput: string;
  isLoading: boolean;
  hasThemeChanges: boolean;
  postData: IInstaAllPostData | undefined;
  selectedPreview: string;
  selectedTab: ThemeSettingsTabs;
  showThemesModal: boolean;
  isModalOpen: boolean;
  disableDiscard: boolean;
  showError: boolean;
  errorMessage: string;

  actions: {
    get(): Promise<void>;
    discard(): void;
    setOverrideCSSInput(newValues: string): void;
    setModernOverrideCSSInput(newValues: string): void;
    setPostData(newPostData: IInstaAllPostData): void;
    setSelectedPreview(newPreview: string): void;
    setSelectedTab(newTab: ThemeSettingsTabs): void;
    setShowThemesModal(newValue: boolean): void;
    setIsModalOpen(newValue: boolean): void;
    setDisableDiscard(newValue: boolean): void;
    setShowHide(): void;
    setSubscriptionActions(): void;
    setThemeState(key: keyof ThemingStore, value: any): void;
    update(themeId: number, updatedThemeProperties: ICustomerPortalTheme): Promise<void>;
    updateCurrentThemeValues(sectionToUpdate: string, updates: any): void;
    updateDefaultThemeValues(sectionToUpdate: string, updates: any): void;
  };
  internal: {
    whileLoading<Type>(fn: () => Promise<Type>): Promise<Type | null>;
  };
}

const useThemingStore = create<ThemingStore>()(
  immer((set, get) => ({
    initialThemeValues: null,
    currentThemeValues: null,
    overrideCSSInput: "",
    modernOverrideCSSInput: "",
    hasThemeChanges: false,
    isLoading: false,
    postData: undefined,
    selectedPreview: "mobile",
    selectedTab: ThemeSettingsTabs.GLOBAL,
    showThemesModal: false,
    isModalOpen: false,
    disableDiscard: false,
    showError: false,
    errorMessage: "",

    actions: {
      async get() {
        const internal = get().internal;

        await internal.whileLoading(async () => {
          const response = await typedFrontendVendorApi.getReq("/customer-portal-theme");
          if (response.type === "error") {
            set(draft => {
              draft.showError = true;
              draft.errorMessage = response.message;
            });
            return;
          }

          if (response.type !== "success") {
            return;
          }

          const validationResult = ThemingApi.CustomerPortalTheme.schema.safeParse(response.body);

          if (!validationResult.success) {
            set(draft => {
              draft.showError = true;
              draft.errorMessage = validationResult.error.errors.map(e => e.message).join(", ");
            });
            return;
          }

          if (response.type === "success") {
            set(draft => {
              draft.initialThemeValues = response.body;
              draft.currentThemeValues = response.body;
              draft.isLoading = false;
              draft.hasThemeChanges = false;
            });
            this.setShowHide();
            this.setSubscriptionActions();
          }
        });
      },

      discard() {
        set(draft => {
          draft.currentThemeValues = get().initialThemeValues;
          draft.hasThemeChanges = false;
        });
      },

      setOverrideCSSInput(newValues) {
        set(draft => {
          draft.overrideCSSInput = newValues;
        });
      },

      setModernOverrideCSSInput(newValues) {
        set(draft => {
          draft.modernOverrideCSSInput = newValues;
        });
      },

      setPostData(newPostData) {
        set(draft => {
          draft.postData = newPostData;
        });
      },

      setSelectedPreview(newPreview) {
        set(draft => {
          draft.selectedPreview = newPreview;
        });
      },

      setSelectedTab(newTab) {
        set(draft => {
          draft.selectedTab = newTab;
        });
      },

      setShowThemesModal(newValue) {
        set(draft => {
          draft.showThemesModal = newValue;
        });
      },

      setIsModalOpen(newValue) {
        set(draft => {
          draft.isModalOpen = newValue;
        });
      },

      setDisableDiscard(newValue) {
        set(draft => {
          draft.disableDiscard = newValue;
        });
      },

      setShowHide() {
        const themeValues = get().currentThemeValues;
        if (themeValues) {
          if (isUndefined(themeValues.modernThemeSettings?.showHide)) {
            this.updateDefaultThemeValues("modernThemeSettings", {
              ...themeValues.modernThemeSettings,
              showHide: defaultCustomerPortalThemeConfig.modernThemeSettings.showHide,
            });
          } else {
            // if showHide is defined, check to see if any keys are missing
            const showHideKeys = Object.keys(themeValues.modernThemeSettings.showHide);
            const defaultShowHideKeys = Object.keys(
              defaultCustomerPortalThemeConfig.modernThemeSettings.showHide
            );
            const missingKeys = defaultShowHideKeys.filter(key => !showHideKeys.includes(key));
            for (const key of missingKeys) {
              this.updateDefaultThemeValues("modernThemeSettings.showHide", {
                ...themeValues.modernThemeSettings.showHide,
                // @ts-ignore - key is a string
                [key]: defaultCustomerPortalThemeConfig.modernThemeSettings.showHide[key],
              });
            }
          }
        }
      },

      setSubscriptionActions() {
        const themeValues = get().currentThemeValues;
        if (themeValues && isUndefined(themeValues?.modernThemeSettings?.subscriptionActions.subscriptionSwap)) {
          this.updateDefaultThemeValues("modernThemeSettings.subscriptionActions", {
            ...themeValues.modernThemeSettings?.subscriptionActions,
            swap: defaultCustomerPortalThemeConfig.modernThemeSettings.subscriptionActions.subscriptionSwap,
          });
        }
      },

      setThemeState<K extends keyof ThemingStore>(key: K, value: ThemingStore[K]) {
        const initialThemeValues = get().initialThemeValues;
        set(draft => {
          draft[key] = value as (typeof draft)[typeof key];
        });

        const updatedThemeValues = get().currentThemeValues;
        set(draft => {
          draft.hasThemeChanges = !isEqual(initialThemeValues, updatedThemeValues);
        });
      },

      async update(themeId, updatedThemeProperties) {
        const internal = get().internal;

        const validationResult = ThemingApi.CustomerPortalTheme.schema.safeParse(updatedThemeProperties);

        if (!validationResult.success) {
          set(draft => {
            draft.showError = true;
            draft.errorMessage = validationResult.error.errors.map(e => e.message).join(", ");
          });
          return;
        }

        await internal.whileLoading(async () => {
          const response = await typedFrontendVendorApi.postReq("/customer-portal-theme", {
            reqBody: {
              themeShopifyId: themeId,
              updatedThemeProperties,
            },
          });

          if (response.type === "error") {
            set(draft => {
              draft.showError = true;
              draft.errorMessage = response.message;
            });
            return;
          }

          if (response.type !== "success") {
            return;
          }

          if (response.type === "success") {
            set(draft => {
              draft.currentThemeValues = updatedThemeProperties;
            });
          }
        });
      },

      updateCurrentThemeValues(sectionToUpdate, updates) {
        const initialThemeValues = get().initialThemeValues;
        const currentThemeValues = get().currentThemeValues;
        if (currentThemeValues) {
          set(draft => {
            setNestedProperty(draft.currentThemeValues, sectionToUpdate, updates);
          });
          const updatedThemeValues = get().currentThemeValues;
          set(draft => {
            draft.hasThemeChanges = !isEqual(initialThemeValues, updatedThemeValues);
          });
        }
      },

      updateDefaultThemeValues(sectionToUpdate, updates) {
        const { currentThemeValues, initialThemeValues } = get();
        if (initialThemeValues && currentThemeValues) {
          set(draft => {
            setNestedProperty(draft.initialThemeValues, sectionToUpdate, updates);
            setNestedProperty(draft.currentThemeValues, sectionToUpdate, updates);
          });
        }
      },
    },
    internal: {
      async whileLoading<Type>(fn: () => Promise<Type>): Promise<Type | null> {
        if (get().isLoading) {
          return null;
        }

        set(draft => {
          draft.isLoading = true;
        });

        const response = await fn();
        set(draft => {
          draft.isLoading = false;
        });
        return response;
      },
    },
  }))
);

const initialStoreState = useThemingStore.getState();

export const ThemingStoreAccess = {
  useActions() {
    return useThemingStore(state => state.actions);
  },

  useIsLoading() {
    return useThemingStore(state => state.isLoading);
  },

  useHasThemeChanges() {
    return useThemingStore(state => state.hasThemeChanges);
  },

  useThemeProperties() {
    return useThemingStore(state => state);
  },

  useThemeValues() {
    return useThemingStore(state => {
      return {
        initialThemeValues: state.initialThemeValues,
        currentThemeValues: state.currentThemeValues,
      };
    });
  },

  useCurrentThemeValues() {
    return useThemingStore(state => state.currentThemeValues);
  },

  testing: {
    store() {
      return useThemingStore.getState;
    },
    actions() {
      return useThemingStore.getState().actions;
    },
    reset() {
      useThemingStore.setState(initialStoreState);
    },
  },
};
